import Ares from '../Weapons/Ares.webp'
import Bucky from '../Weapons/Bucky.webp'
import Bulldog from '../Weapons/Bulldog.webp'
import Classic from '../Weapons/Classic.webp'
import Frenzy from '../Weapons/Frenzy.webp'
import Ghost from '../Weapons/Ghost.webp'
import Guardian from '../Weapons/Guardian.webp'
import Judge from '../Weapons/Judge.webp'
import Marshal from '../Weapons/Marshal.webp'
import Odin from '../Weapons/Odin.webp'
import Operator from '../Weapons/Operator.webp'
import Outlaw from '../Weapons/Outlaw.webp'
import Phantom from '../Weapons/Phantom.webp'
import Sheriff from '../Weapons/Sheriff.webp'
import Shorty from '../Weapons/Shorty.webp'
import Spectre from '../Weapons/Spectre.webp'
import Stinger from '../Weapons/Stinger.webp'
import TacticalKnife from '../Weapons/TacticalKnife.webp'
import Vandal from '../Weapons/Vandal.webp'

const GUNS = 
    {
    Ares:{img:Ares, gun:"Ares"}, 
    Bucky:{img:Bucky, gun:"Bucky"}, 
    Bulldog:{img:Bulldog, gun:"Bulldog"}, 
    Classic:{img:Classic, gun:"Classic"},
    Guardian:{img:Guardian, gun:"Guardian"},
    Frenzy:{img:Frenzy, gun:"Frenzy"},
    Ghost:{img:Ghost, gun:"Ghost"},
    Judge:{img:Judge, gun:"Judge"}, 
    Marshal:{img:Marshal, gun:"Marshal"}, 
    Vandal:{img:Vandal, gun:"Vandal"}, 
    Odin:{img:Odin, gun:"Odin"}, 
    Operator:{img:Operator, gun:"Operator"}, 
    Phantom:{img:Phantom, gun:"Phantom"},
    Shorty:{img:Shorty, gun:"Shorty"},
    Sheriff:{img:Sheriff, gun:"Sheriff"},
    Spectre:{img:Spectre, gun:"Spectre"}, 
    Stinger:{img:Stinger, gun:"Stinger"}, 
    Knife:{img:TacticalKnife, gun:"Knife"},
    Outlaw: {img: Outlaw, gun:"Outlaw"}
}

                    //Added Shorty, Sheriff, Classic, Frenzy

export default GUNS;